const _void = (obj:any) => obj

export const getBrowserDetails = () => {
	let ios = null, android = null, userAgent = null
	try {
		if (window && window.navigator && window.navigator.userAgent) {
			ios = /iPad|iPhone|iPod/.test(window.navigator.userAgent)
			android = /android/.test(window.navigator.userAgent)
			userAgent = window.navigator.userAgent
			let name = ''
			let tem 
			let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
			if(/trident/i.test(M[1])) {
				tem =  /\brv[ :]+(\d+)/g.exec(userAgent) || []
				name = 'IE ' + (tem[1] || '')
			}
			if (M[1] === 'Chrome') {
				tem= userAgent.match(/\b(OPR|Edge|Edg)\/(\d+)/)
				if (tem != null) {
					let val = tem.slice(1).join(' ').replace('OPR', 'Opera') || ''
					if (val.indexOf('Edg ') == 0)
						val = val.replace('Edg', 'Edge')
					name = val
				}
			}
			M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?']
			if ((tem= userAgent.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1])
				name = M.join(' ')

			const [browser, version] = name.split(' ')
			return {
				name: `${browser}`.toLowerCase().trim(),
				version,
				ios,
				android,
				userAgent
			}
		} else 
			return {
				name: null,
				version: null,
				ios,
				android,
				userAgent
			}
	} catch(err) {
		_void(err)
		return {
			name: null,
			version: null,
			ios,
			android,
			userAgent
		}
	}
}

export const isInstallable = (deferredPrompt?:any) => {
	const { name } = getBrowserDetails()
	const supported = name == 'chrome' || name == 'edge' || name == 'opera'
	if (deferredPrompt)
		return { yes:true, alreadyInstalled:false }
	else if (supported)
		return { yes:false, alreadyInstalled: false } // alreadyInstalled should be true, but a bug on mobile prevent us to set it that way
	else
		return { yes:false, alreadyInstalled:false }
}
