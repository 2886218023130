import { useMutation, gql } from '@apollo/client'

const INSERT_LOG = gql`
mutation engagement_log($session_id: ID!, $data:[EngagementLogInput]) {
	engagement_log (session_id: $session_id, data: $data) {
		message
	}
}`

const MAX_SESSION_DURATION = 30*60*1000 // 30 minutes
const PERIODIC_LOG_CHECK = 10000
const MAX_COMMIT_LOGS_DELAY = 5000

const sessionContext:any = {
	data:{}
}

const newId = () => `${Math.random().toString(36).substr(2, 20)}${Math.random().toString(36).substr(2, 20)}`

setInterval(() => {
	if (Object.keys(sessionContext.data||{}).length && sessionContext.save) {
		// Create a new session ID if one of the following is true:
		//		- There is no session ID yet
		//		- There is no 'last_saved' timestamp yet
		//		- The time difference between the now and the 'last_saved' is greated 
		//		  than 30 min. (i.e., no activity for the past 30 minutes). 
		const createNewSession = !sessionContext.session_id || !sessionContext.last_saved || (Date.now() - sessionContext.last_saved > MAX_SESSION_DURATION)
		if (createNewSession)
			sessionContext.session_id = newId()

		// Take a copy of the current session
		const session_id = sessionContext.session_id
		const data = [
			...(createNewSession ? [{
				level: 'INFO',
				category: 'session',
				title: 'start', 
				timestamp: new Date().toISOString()
			}] : []), 
			...Object.values(sessionContext.data)
		]
		const save = sessionContext.save

		// Save the current session data using a random delay to smooth the stress on the server when a lot of
		// clients are connected.
		setTimeout(() => {
			save(session_id, data)
			sessionContext.last_saved = Date.now()
		}, Math.floor(Math.random()*4500))

		// Reset the session
		sessionContext.data = {}
	}
}, PERIODIC_LOG_CHECK)

const logActivity = (category:string, level:string) => () => {

	const [insertLog] = useMutation(INSERT_LOG)
	sessionContext.save = (session_id:string, data:any) => {
		insertLog({ 
			variables:{ session_id, data },
			onError(error) {
				console.error('Failed to log')
				console.error(error?.message)
			}
		})
	}

	return ({ ...log }) => {
		const { title, content, metric } = log
		// Creates a unique key for this log using a timestamp ts rounded to 4.5 sec. This prevents logging 
		// duplicated logs. This can occur when a component is re-rendered multiple times in a row due to useEffect.
		const ts = Math.floor(Date.now()/MAX_COMMIT_LOGS_DELAY)*MAX_COMMIT_LOGS_DELAY
		const key = JSON.stringify({ level, category, title, content, ts })

		if (!sessionContext.data[key]) {
			sessionContext.data[key] = {
				level,
				category,
				title: title || 'na',
				content, 
				metric,
				timestamp: new Date().toISOString()
			}
		}
	}
}

export const useLogSession = logActivity('session', 'INFO')
export const useLogFeatureInfo = logActivity('feature', 'INFO')
export const useLogFeatureWarn = logActivity('feature', 'WARN')
export const useLogFeatureError = logActivity('feature', 'ERROR')
export const useLogSystemInfo = logActivity('system', 'INFO')
export const useLogSystemWarn = logActivity('system', 'WARN')
export const useLogSystemError = logActivity('system', 'ERROR')