import './Logo.css'

const Logo = ({ ...props }) => {
	return <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="405.69 389.07 488.6 121.86" {...props}>
		<defs></defs>
		<g>
			<path className="cls-2" d="M520.47,478.49c-10.22,19.3-30.51,32.44-53.87,32.44-6.69,0-13.13-1.08-19.16-3.09l44.51-44.5c3.69-3.69,9.67-3.69,13.36,0l15.16,15.16Z"/>
			<path className="cls-1" d="M485.77,392.17l-46.74,46.74c-2.46,2.46-6.44,2.45-8.89,0l-17.38-17.4c10.22-19.3,30.49-32.44,53.85-32.44,6.69,0,13.13,1.08,19.16,3.09"/>
			<path className="cls-4" d="M453.27,488.41l-15.35,15.35c-19.19-10.25-32.23-30.46-32.23-53.75,0-6.78,1.1-13.3,3.16-19.37l44.41,44.41c3.69,3.69,3.69,9.67,0,13.37"/>
			<path className="cls-3" d="M527.53,450c0,6.78-1.1,13.3-3.16,19.37l-44.43-44.41c-3.69-3.69-3.69-9.68,0-13.37l15.35-15.34c19.19,10.25,32.25,30.46,32.25,53.75"/>
		</g>
		<g>
			<polygon className="cls-4" points="558.96 478.25 536.86 397.71 555.9 397.71 572.56 465.17 567.46 465.17 585.03 397.71 603.17 397.71 620.73 465.17 615.63 465.17 632.29 397.71 651.34 397.71 629.23 478.25 608.83 478.25 591.15 413.38 597.05 413.38 579.36 478.25 558.96 478.25"/>

			<path className="cls-4" d="M648.47,478.25l28.56-80.54h23.35l28.56,80.54h-19.16l-5.67-16.43h-30.94l-5.67,16.43h-19.04Zm29.58-31.03h21.19l-12.92-38.06h4.76l-13.03,38.06Z"/>
			<polygon className="cls-4" points="736.83 478.25 736.83 397.71 750.32 397.71 795.66 454.68 788.4 456.3 788.4 397.71 805.97 397.71 805.97 478.25 792.37 478.25 747.83 420.84 754.4 419.22 754.4 478.25 736.83 478.25"/>
			<path className="cls-4" d="M821.76,478.25v-80.54h27.54c9.14,0,17.07,1.73,23.8,5.19,6.72,3.46,11.94,8.22,15.64,14.27,3.7,6.05,5.55,12.97,5.55,20.76s-1.85,14.72-5.55,20.81c-3.7,6.09-8.92,10.86-15.64,14.32-6.73,3.46-14.66,5.19-23.8,5.19h-27.54Zm17.57-14.6h10.43c5.52,0,10.28-1.06,14.28-3.19,4.01-2.12,7.12-5.12,9.35-8.97,2.23-3.85,3.34-8.38,3.34-13.57s-1.12-9.8-3.34-13.62c-2.23-3.82-5.35-6.77-9.35-8.87-4.01-2.09-8.77-3.14-14.28-3.14h-10.43v51.35Z"/>
		</g>
		<g>
			<path className="cls-4" d="M552.64,489.08h1.81l-3.96,13.08h-1.93l-2.94-10.57-3.15,10.57-1.91,.02-3.7-13.1h1.81l2.91,11.12,3.17-11.12h1.89l2.93,11.08,3.08-11.08Z"/>
			<path className="cls-4" d="M565.14,489.08v1.36h-5.21v4.45h4.64v1.34h-4.64v4.57h5.21v1.36h-6.93v-13.08h6.93Z"/>
			<path className="cls-4" d="M578.65,502.16l-1.02-2.87h-5.81l-1.02,2.87h-1.76l4.76-13.08h1.89l4.74,13.08h-1.78Zm-6.34-4.23h4.83l-2.42-6.76-2.42,6.76Z"/>
			<path className="cls-4" d="M583.97,490.46v-1.38h8.63v1.38h-3.45v11.7h-1.72v-11.7h-3.45Z"/>
			<path className="cls-4" d="M605.06,489.08h1.74v13.08h-1.74v-5.91h-6.59v5.91h-1.72v-13.08h1.72v5.81h6.59v-5.81Z"/>
			<path className="cls-4" d="M618.44,489.08v1.36h-5.21v4.45h4.64v1.34h-4.64v4.57h5.21v1.36h-6.93v-13.08h6.93Z"/>
			<path className="cls-4" d="M624.65,496.76v5.4h-1.72v-13.08h4.25c1.47,0,2.6,.36,3.37,1.08,.77,.72,1.16,1.59,1.16,2.63s-.28,1.9-.85,2.6c-.57,.69-1.42,1.13-2.55,1.3l3.61,5.47h-2.15l-3.44-5.4h-1.68Zm0-6.3v4.95h2.53c.94,0,1.64-.22,2.1-.65,.46-.43,.69-1.04,.69-1.82,0-1.65-.93-2.47-2.79-2.47h-2.53Z"/>
			<path className="cls-4" d="M653.04,502.16l-1.02-2.87h-5.81l-1.02,2.87h-1.76l4.76-13.08h1.89l4.74,13.08h-1.78Zm-6.34-4.23h4.83l-2.42-6.76-2.42,6.76Z"/>
			<path className="cls-4" d="M667.57,489.08h1.72v13.08h-1.72l-6.91-10.38v10.38h-1.72v-13.08h1.72l6.91,10.38v-10.38Z"/>
			<path className="cls-4" d="M678.14,489.08c2.11,0,3.78,.59,5,1.77,1.22,1.18,1.83,2.79,1.83,4.83s-.61,3.63-1.83,4.77c-1.22,1.14-2.89,1.71-5,1.71h-4.13v-13.08h4.13Zm-2.42,11.76h2.42c1.65,0,2.91-.45,3.78-1.36s1.3-2.17,1.3-3.79-.43-2.89-1.3-3.79-2.13-1.36-3.78-1.36h-2.42v10.31Z"/>
			<path className="cls-4" d="M705.21,489.08h1.72v13.08h-1.72l-6.91-10.38v10.38h-1.72v-13.08h1.72l6.91,10.38v-10.38Z"/>
			<path className="cls-4" d="M718.58,489.08v1.36h-5.21v4.45h4.64v1.34h-4.64v4.57h5.21v1.36h-6.93v-13.08h6.93Z"/>
			<path className="cls-4" d="M722.48,490.46v-1.38h8.63v1.38h-3.45v11.7h-1.72v-11.7h-3.45Z"/>
			<path className="cls-4" d="M750.08,489.08h1.81l-3.96,13.08h-1.93l-2.94-10.57-3.15,10.57-1.91,.02-3.7-13.1h1.81l2.91,11.12,3.17-11.12h1.89l2.93,11.08,3.08-11.08Z"/>
			<path className="cls-4" d="M761.63,502.29c-1.88,0-3.45-.63-4.74-1.9s-1.93-2.86-1.93-4.79,.64-3.52,1.93-4.78c1.28-1.25,2.86-1.88,4.74-1.88s3.45,.63,4.73,1.88c1.28,1.25,1.92,2.85,1.92,4.78s-.64,3.54-1.92,4.79c-1.28,1.26-2.85,1.89-4.73,1.89Zm3.51-2.94c.92-.94,1.38-2.19,1.38-3.74s-.46-2.79-1.38-3.73-2.09-1.41-3.52-1.41-2.61,.47-3.53,1.41c-.93,.94-1.39,2.18-1.39,3.73s.46,2.79,1.4,3.74c.93,.94,2.11,1.42,3.53,1.42s2.59-.47,3.51-1.42Z"/>
			<path className="cls-4" d="M774.01,496.76v5.4h-1.72v-13.08h4.25c1.47,0,2.6,.36,3.37,1.08,.77,.72,1.16,1.59,1.16,2.63s-.28,1.9-.85,2.6c-.57,.69-1.42,1.13-2.55,1.3l3.61,5.47h-2.15l-3.44-5.4h-1.68Zm0-6.3v4.95h2.53c.94,0,1.64-.22,2.1-.65,.46-.43,.69-1.04,.69-1.82,0-1.65-.93-2.47-2.79-2.47h-2.53Z"/>
			<path className="cls-4" d="M787.55,502.16h-1.72v-13.08h1.72v6.06l5.38-6.06h2.25l-6.12,6.63,6.12,6.46h-2.28l-5.34-5.85v5.85Z"/>
			<path className="cls-4" d="M805.93,489.08v1.36h-5.21v4.45h4.64v1.34h-4.64v4.57h5.21v1.36h-6.93v-13.08h6.93Z"/>
			<path className="cls-4" d="M814.56,489.08c2.11,0,3.78,.59,5,1.77,1.22,1.18,1.83,2.79,1.83,4.83s-.61,3.63-1.83,4.77c-1.22,1.14-2.89,1.71-5,1.71h-4.13v-13.08h4.13Zm-2.42,11.76h2.42c1.65,0,2.91-.45,3.78-1.36,.87-.91,1.3-2.17,1.3-3.79s-.43-2.89-1.3-3.79-2.13-1.36-3.78-1.36h-2.42v10.31Z"/>
			<path className="cls-4" d="M837.13,489.08c2.11,0,3.78,.59,5,1.77,1.22,1.18,1.83,2.79,1.83,4.83s-.61,3.63-1.83,4.77c-1.22,1.14-2.89,1.71-5,1.71h-4.13v-13.08h4.13Zm-2.42,11.76h2.42c1.65,0,2.91-.45,3.78-1.36,.87-.91,1.3-2.17,1.3-3.79s-.43-2.89-1.3-3.79c-.87-.91-2.13-1.36-3.78-1.36h-2.42v10.31Z"/>
			<path className="cls-4" d="M857.04,502.16l-1.02-2.87h-5.81l-1.02,2.87h-1.76l4.76-13.08h1.89l4.74,13.08h-1.78Zm-6.34-4.23h4.83l-2.42-6.76-2.42,6.76Z"/>
			<path className="cls-4" d="M862.37,490.46v-1.38h8.63v1.38h-3.45v11.7h-1.72v-11.7h-3.45Z"/>
			<path className="cls-4" d="M884.17,502.16l-1.02-2.87h-5.81l-1.02,2.87h-1.76l4.76-13.08h1.89l4.74,13.08h-1.78Zm-6.34-4.23h4.83l-2.42-6.76-2.42,6.76Z"/>
		</g>
	</svg>
}

export default Logo