import { catchErrors, wrapErrorsFn } from 'puffy-core/error'

const TWO_MINUTES_MS = 2*60*1000

const NAVIGATOR = navigator === undefined ? null : navigator
const CARDINAL_DIR = [
	{ name: 'N', 	from:348.75,	to:	360 },
	{ name: 'N', 	from:0,			to:	11.25 },
	{ name: 'NNE', 	from:11.25,		to:	33.75 },
	{ name: 'NE', 	from:33.75,		to:	56.25 },
	{ name: 'ENE', 	from:56.25,		to:	78.75 },
	{ name: 'E', 	from:78.75,		to:	101.25 },
	{ name: 'ESE', 	from:101.25,	to:	123.75 },
	{ name: 'SE', 	from:123.75,	to:	146.25 },
	{ name: 'SSE', 	from:146.25,	to:	168.75 },
	{ name: 'S', 	from:168.75,	to:	191.25 },
	{ name: 'SSW', 	from:191.25,	to:	213.75 },
	{ name: 'SW', 	from:213.75,	to:	236.25 },
	{ name: 'WSW', 	from:236.25,	to:	258.75 },
	{ name: 'W', 	from:258.75,	to:	281.25 },
	{ name: 'WNW', 	from:281.25,	to:	303.75 },
	{ name: 'NW', 	from:303.75,	to:	326.25 },
	{ name: 'NNW', 	from:326.25,	to:	348.75 },
]

const _getLoc = ():any => new Promise((next:Function) => {
	try {
		// doc: https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates/longitude
		if (NAVIGATOR && NAVIGATOR.geolocation && NAVIGATOR.geolocation.getCurrentPosition) {
			NAVIGATOR.geolocation.getCurrentPosition((position:any) => {
				const { latitude, longitude, altitude } = position?.coords || {}
				next([null, {
					latitude: latitude || null, 
					longitude: longitude || null, 
					altitude: altitude || null
				}])
			}, (error:any) => {
				const err = error?.code ? new Error(`${error?.message||''} (code ${error?.code})`) : error
				next([[err], null])
			})
		} else
			next([new Error('\'GeolocationCoordinates\' web API not supported by this device'), null])
	} catch(err) {
		next([[err], null])
	}
})

const _toRad = (v:any) => v * Math.PI / 180
const _toDegrees = (v:any) => v / Math.PI * 180

let _memoizedLoc:any = null
export const getDeviceLocation = () => catchErrors((async () => {
	const e = wrapErrorsFn(`Failed to access device's geo coordinates`)

	if (_memoizedLoc && _memoizedLoc.timestamp && (Date.now() - _memoizedLoc.timestamp) < TWO_MINUTES_MS)
		return _memoizedLoc.coord

	const [errors, coord] = await _getLoc()
	if (errors)
		throw e(errors)

	_memoizedLoc = {
		coord,
		timestamp: Date.now()
	}

	return _memoizedLoc.coord
})())


export const getDeviceConfig = async () => {
	const config = {
		supported: true,
		enabled: true
	}

	const [errors] = await _getLoc()
	if (errors) {
		if (errors.some((e:any) => e && e.message && e.message.indexOf('web API not supported by this device')>=0)) {
			config.supported = false
			config.enabled = false
		} else if (errors.some((e:any) => e && e.message && e.message.indexOf('(code 1)') >= 0))
			config.enabled = false
	}

	return config
}

/**
 *This function takes in latitude and longitude of two locations and returns the distance between them as the crow flies (in km)
 * 
 * @type {Number}
 */
export const getDistance = (pointA:any, pointB:any) => {
	const { latitude:lat1, longitude:lon1 } = pointA || {}
	const { latitude:lat2, longitude:lon2 } = pointB || {}

	if (lat1 === undefined || lon1 === undefined || lat2 === undefined || lon2 === undefined)
		return null

	var R = 6371
	const dLat = _toRad(lat2-lat1)
	const dLon = _toRad(lon2-lon1)

	const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
	Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(_toRad(lat1)) * Math.cos(_toRad(lat2)) 
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)) 
	return R * c
}

/**
 *This function takes in latitude and longitude of two locations and returns the direction between them.
 * 
 * @type {Number}
 */
export const getBearing = (pointA:any, pointB:any) => {
	if (!pointA || pointA.latitude === undefined || pointA.longitude === undefined || !pointB || pointB.latitude === undefined || pointB.longitude === undefined)
		return null

	const lat1 = _toRad(pointA.latitude)
	const lon1 = _toRad(pointA.longitude)
	const lat2 = _toRad(pointB.latitude)
	const lon2 = _toRad(pointB.longitude)

	const y = Math.sin(lon2 - lon1) * Math.cos(lat2)
	const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1)
	let bearing = Math.atan2(y, x)
	bearing = _toDegrees(bearing)
	bearing = (bearing + 360) % 360

	return {
		degrees: bearing,
		name: (CARDINAL_DIR.find(c => c.from <= bearing && bearing <= c.to)||{}).name
	}
}







