import { useHistory } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import SearchIcon from '@mui/icons-material/Search'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Badge from '@mui/material/Badge'
import { useTheme } from '@mui/material/styles'
import styled from 'styled-components'
import { useLogFeatureInfo } from '../utils/session'
import { SETTINGS_PATH, NOTIFICATION_PATH } from '../path'
import './SearchBar.css'

const MyBadge = styled(Badge)`
	& span.MuiBadge-badge {
		top: 3px;
		right: 3px;
		height: 16px;
		min-width: 14px;
		font-size: 0.6rem;
	}
`

const FakeInput = styled.div`
	padding-left: 8px;
	flex-grow: 1;
	cursor: text;
`

export function SearchBar({ ...props }) {
	const { onSearchMode, value, newMessages=0, disabled } = props || {}
	
	const history = useHistory()
	const theme = useTheme()
	const logFeat = useLogFeatureInfo()

	const handleClick = () => {
		logFeat({ title:'search' })
		if (onSearchMode && !disabled)
			onSearchMode()
	}

	const goToSettings = () => {
		logFeat({ title:'settings' })
		history.push(SETTINGS_PATH)
	}

	const goToNotifications = () => {
		logFeat({ title:'notifications' })
		history.push(NOTIFICATION_PATH)
	}

	return (
		<Paper
			component="form"
			elevation={1}
			square={false}
			sx={{ 
				p: '2px 4px', 
				display: 'flex', 
				alignItems: 'center', 
				width: '100%', 
				'--bagde-color':theme.palette.error.light,
				borderRadius: '45px'
			}}>
			<IconButton sx={{ p: '10px' }} aria-label="menu" onClick={handleClick} disabled={disabled}>
				<SearchIcon/>
			</IconButton>
			<FakeInput onClick={handleClick}>{value ? 
				<Typography>{value}</Typography> : 
				<Typography color="text.secondary" sx={{ fontStyle:'italic' }}>Search towers</Typography>}
			</FakeInput>
			<div> 
				<IconButton sx={{ p: '10px' }} aria-label="search" onClick={goToNotifications}>
					<MyBadge badgeContent={newMessages} color="error" className="custom-mui-bagde">
						<NotificationsNoneIcon color="action" />
					</MyBadge>
				</IconButton>
				<IconButton color="primary" sx={{ p: '10px' }} aria-label="directions" onClick={goToSettings}>
					<AccountCircleIcon />
				</IconButton>
			</div>
		</Paper>
	)
}
