class Toast {
	present: Function
	dismiss: Function
	closeText: string
	duration?: number
	error?: boolean

	constructor(present:Function, dismiss:Function, options?:any) {
		this.present = present
		this.dismiss = dismiss
		this.closeText = (options||{}).closeText||'hide'
		this.duration = (options||{}).duration
		this.error = (options||{}).error
	}

	show = (message: string, opts?:any) => {
		const duration = opts?.duration === null ? null : (this.duration || opts?.duration)
		const error = opts?.error || this.error
		const closeText = opts?.closeText || this.closeText
		const self:any = this
		self.present({
			buttons: [{ 
				text: closeText, 
				handler: () => self.dismiss() 
			}],
			color: error ? 'danger' : null,
			message,
			duration
		})
	}
}

export default Toast