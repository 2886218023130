import { IonContent, IonPage } from '@ionic/react'
import { useHistory, useLocation } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { TitleHeader } from '../components/Header'

const Container = styled.div`
	padding: 20px 26px 20px 26px;
`

const Terms = () => {
	const head = <Helmet>
		<title>Wand - Terms &amp; Conditions</title>
	</Helmet>

	const history = useHistory()
	const location = useLocation<any>()

	const onBack = () => {
		const { origin } = location?.state||{}
		if (origin != 'settings' && origin != 'login')
			history.push('/')
		else
			history.goBack()
	}

	return (
		<IonPage>
			{head}
			<TitleHeader title="Terms &amp; Conditions" onBack={onBack} />
			<IonContent fullscreen>
				<Container>
					<Typography>Waiting for content... Issue tracked in GitHub at <a href="https://github.com/GoannaAg/inversion-tower-doc/issues/19" target="_blank" rel="noreferrer">https://github.com/GoannaAg/inversion-tower-doc/issues/19</a></Typography>
				</Container>
			</IonContent>
		</IonPage>
	)
}

export default Terms