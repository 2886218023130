import { useEffect, useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import { Redirect, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import { TitleHeader } from '../components/Header'
import { getSessionUser } from '../auth'
import { LOGIN_PATH, SETTINGS_PATH } from '../path'

const Section = styled.div`
	display: flex;
	flex-direction: column;
	padding: 26px;
`

const ChannelDiv = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 35px;
	cursor: pointer;
`

const Channel = ({ name, value, onChange }:{ name:string, value:boolean, onChange:Function }) => {
	return (
		<ChannelDiv onClick={() => onChange(!value)}>
			<Box sx={{ display:'flex', alignItems:'center', gap:'6px', width:'100%' }}>
				<Typography variant="body2">{name}</Typography>
			</Box>
			<Box sx={{ display:'flex', alignItems:'center', justifyContent:'flex-end', width:'100%' }}>
				<Switch checked={value} color="primary" onChange={(e:any) => onChange(e.target.checked)} />
			</Box>
		</ChannelDiv>
	)
}

const SectionTitle = ({ title }:{ title:string }) => {
	return <Typography variant="body1" sx={{ fontWeight:500, paddingBottom:'10px' }} >{title}</Typography>
}


const NotificationSetting = () => {
	const head = <Helmet>
		<title>Wand - Settings - Notification Types</title>
	</Helmet>
	
	const history = useHistory()
	const [weatherPushOn, setWeatherPushOn] = useState(false)
	const [weatherEmailOn, setWeatherEmailOn] = useState(false)
	const [productPushOn, setProductPushOn] = useState(false)
	const [productEmailOn, setProductEmailOn] = useState(false)
	const [goannaPushOn, setGoannaPushOn] = useState(false)
	const [goannaEmailOn, setGoannaEmailOn] = useState(false)

	// Manages redirection to login page if user is not authenticated
	const [redirect, setRedirect] = useState<boolean | null>(null)
	useEffect(() => getSessionUser().then((resp: any) => setRedirect(resp && resp[0] ? true : false)), [])
	if (redirect === null)
		return null

	const showAlert = () => {
		alert(`WORK IN PROGRESS: Those details are not saved to the DB yet. GitHub issue https://github.com/GoannaAg/inversion-tower-doc/issues/9`)
	}

	const onWeatherPush = (val:boolean) => {
		setWeatherPushOn(val)
		showAlert()
	}
	const onWeatherEmail = (val:boolean) => {
		setWeatherEmailOn(val)
		showAlert()
	}
	const onProductPush = (val:boolean) => {
		setProductPushOn(val)
		showAlert()
	}
	const onProductEmail = (val:boolean) => {
		setProductEmailOn(val)
		showAlert()
	}
	const onGoannaPush = (val:boolean) => {
		setGoannaPushOn(val)
		showAlert()
	}
	const onGoannaEmail = (val:boolean) => {
		setGoannaEmailOn(val)
		showAlert()
	}

	return redirect ? <Redirect to={LOGIN_PATH} /> :(
		<div>
			<IonPage>
				{head}
				<TitleHeader title="Notifications" onBack={() => history.push(SETTINGS_PATH)} />
				<IonContent fullscreen>
					<Section>
						<SectionTitle title="Weather Conditions News" />
						<Channel name="Push" value={weatherPushOn} onChange={onWeatherPush}/>
						<Channel name="Email" value={weatherEmailOn} onChange={onWeatherEmail}/>
					</Section>
					<Divider/>
					<Section>
						<SectionTitle title="Products News" />
						<Channel name="Push" value={productPushOn} onChange={onProductPush}/>
						<Channel name="Email" value={productEmailOn} onChange={onProductEmail}/>
					</Section>
					<Divider/>
					<Section>
						<SectionTitle title="Goanna Ag News &amp; Offers" />
						<Channel name="Push" value={goannaPushOn} onChange={onGoannaPush}/>
						<Channel name="Email" value={goannaEmailOn} onChange={onGoannaEmail}/>
					</Section>
				</IonContent>
			</IonPage>
		</div>
	)
}

export default NotificationSetting




