import styled from 'styled-components'
import { IonProgressBar } from '@ionic/react'

const MyIonProgressBar = styled(IonProgressBar)`
	position: absolute;
	z-index:20;
`

const ProgressBar = () => {
	return <MyIonProgressBar type="indeterminate"/>
}

export default ProgressBar