import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

const SaveCancelButton = ({ ...props }) => {

	const onCancel = props.onCancel || (() => null)
	const onSave = props.onSave || (() => null)

	return <Box sx={{ width:'100%', display:'flex', justifyContent:'flex-end', ...(props.sx||{}) }}>
		<Button 
			variant="outlined" 
			onClick={() => onCancel()} 
			sx={{ width:'50%', textTransform: 'none' }}
			disabled={props.saving}
			size="large">
			Cancel
		</Button>
		<div style={{ width:'20px' }}>&nbsp;</div> {/*This is a hack because iOS does not support the flex `gap`*/}
		<Box sx={{ position: 'relative', width:'50%' }}>
			<Button
				fullWidth
				variant="contained"
				onClick={onSave} 
				sx={{ textTransform: 'none' }}
				disabled={props.saving}
				size="large"
			>Save</Button>
			{props.saving && <CircularProgress
				size={24}
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					marginTop: '-12px',
					marginLeft: '-12px',
				}}
			/>}
		</Box>
	</Box>
}

export default SaveCancelButton




