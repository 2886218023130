import styled from 'styled-components'
import RefreshIcon from '@mui/icons-material/Refresh'
import './RefreshSpinner.css'

const REFRESH_ICON_INIT_YPOS = 50
const REFRESH_ICON_MAX_YPOS = 230
const REFRESH_ICON_ACTION_YPOS = 170 // Y pos beyond which a refresh action is triggered. Below this number, the refresh wheel goes back up without refresh

const RefreshDiv = styled.div.attrs(() => ({ 
	id:'refresh-wheel', 
	style:{ 
		'--refresh-icon-rotate': '0deg',
		'--refresh-transy': REFRESH_ICON_INIT_YPOS,
		'--refresh-trans-time': `50ms`,
		'--refresh-scale': `1`
	} 
}))`
	transform: translateY(calc(var(--refresh-transy) * 1px));
	transition: transform var(--refresh-trans-time, 50ms) ease-in-out, opacity 300ms linear;
	opacity: calc(var(--refresh-transy) - ${REFRESH_ICON_INIT_YPOS});
	display: flex;
	align-items: center;
	justify-content: center;

	& div {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		background-color: white;
		border-radius: 20px;
		border: 1px solid #e5e5e5;
		box-shadow: 0px 2px 1px -1px rgb(0,0,0,0.2), 0px 1px 1px 0px rgb(0,0,0,0.14), 0px 1px 6px 0px rgb(0,0,0,0.12);
		transform: scale(var(--refresh-scale, 1));
		transition: transform 100ms ease-in;
	}

	& svg {
		font-size: 30px;
		transform: rotate(var(--refresh-icon-rotate, 0deg));
	}
`

export const moveRefreshSpinner = (moveY:number) => {
	const wheel = getRefreshSpinner()
	if (wheel && wheel.transY < REFRESH_ICON_MAX_YPOS) {
		let nextTransY = REFRESH_ICON_INIT_YPOS+moveY 
		if (nextTransY > REFRESH_ICON_MAX_YPOS)
			nextTransY = REFRESH_ICON_MAX_YPOS
		wheel.move(nextTransY, wheel.deg + 5)
	}
}

/**
 * Moves the refresh wheel to its hidden initial position or into its processing position.
 * 
 * @param  {Boolean}	collapse		
 * 
 * @return {Boolean}	refreshData		True means the data have to be refreshed.
 */
export const resetRefreshSpinner = (collapse?:boolean) => {
	const wheel = getRefreshSpinner()
	if (wheel) {
		if (collapse) {
			wheel.collapse()
			setTimeout(() => {
				wheel.move(REFRESH_ICON_INIT_YPOS, null, 200)
			}, 250)
		} else if (wheel.transY < REFRESH_ICON_ACTION_YPOS)
			wheel.move(REFRESH_ICON_INIT_YPOS, null, 200)
		else {
			wheel.move(REFRESH_ICON_ACTION_YPOS, null, 200)
			return true
		}
	}
	return false
}

export const getRefreshSpinner = () => {
	const wheelEl:any = document.getElementById('refresh-wheel')
	if (wheelEl) {
		const rotate = wheelEl.style.getPropertyValue('--refresh-icon-rotate') || '0deg'
		const transY = (wheelEl.style.getPropertyValue('--refresh-transy') || -60)*1

		const deg = rotate.replace('deg', '')*1
		if (!isNaN(deg) && !isNaN(transY)) {
			wheelEl.deg = deg
			wheelEl.transY = transY
			wheelEl.move = (dy:number, rotate?:number, trans?:number) => {
				if (trans !== undefined && trans !== null && trans > 0) {
					wheelEl.style.setProperty('--refresh-trans-time', `${trans}ms`)
					setTimeout(() => {
						wheelEl.style.setProperty('--refresh-trans-time', `50ms`)
					}, trans*1.5)
				}
				wheelEl.style.setProperty('--refresh-transy', dy)
				if (rotate !== undefined && rotate !== null)
					wheelEl.style.setProperty('--refresh-icon-rotate', `${rotate}deg`)
			}
			wheelEl.collapse = () => {
				wheelEl.style.setProperty('--refresh-scale', 0)
				setTimeout(() => {
					wheelEl.style.setProperty('--refresh-scale', 1)
				}, 400)
			}
			return wheelEl
		} else
			return null
	} else
		return null
}

const RefreshSpinner = ({ spin }:{ spin?:boolean }) => {
	return (
	<RefreshDiv>
		<div>
			<RefreshIcon className={spin ? 'refresh-spinner' : ''}/>
		</div>
	</RefreshDiv>)
}

export default RefreshSpinner

