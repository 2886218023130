import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TrapFocus from '@mui/material/Unstable_TrapFocus'

const DatePickerField = ({ ...props }:any) => {
	const { required, time, date:_date, error, disabled } = props
	
	const roundDateToDay = (date?:any) => {
		if (date)
			return new Date(Math.floor(date.getTime()/86400000)*86400000)
		else
			return date
	}

	const date = !time ? roundDateToDay(_date) : _date

	const inputProps:any = {}
	if (required)
		inputProps.required = true

	const handleDateChange = (newValue:any) => {
		if (props.onChange)
			props.onChange(newValue)
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<TrapFocus disableEnforceFocus open>
				<Box sx={{ display:'flex', width:'100%' }}>
					<MobileDatePicker
						label={props.label}
						inputFormat="dd/MM/yyyy"
						minDate={props.minDate}
						maxDate={props.maxDate}
						value={date}
						onChange={handleDateChange}
						disabled={disabled}
						renderInput={(params) => {
							const p = { ...params, ...inputProps, error, disabled }
							return <TextField { ...p } sx={{ width: props.time ? '60%' : '100%' }} />
						}}
					/>
					{props.time && <MobileTimePicker
						label={props.labelTime||'Time'}
						value={date}
						onChange={handleDateChange}
						renderInput={(params) => {
							const p = { ...params, ...inputProps, error, disabled }
							return <TextField { ...p } sx={{ width: '40%', marginLeft:'15px' }} />
						}}
					/>}
				</Box>
			</TrapFocus>
		</LocalizationProvider>
	)
}

export default DatePickerField