import * as React from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'

export default function SearchField({ ...props }) {
	const { onBack, value, onChange, onClear } = props

	return (
		<Paper
			component="form"
			elevation={2}
			sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', borderRadius: '45px' }}>
			<IconButton sx={{ p: '10px' }} aria-label="back" onClick={onBack}>
				<ArrowBackIcon />
			</IconButton>
			<InputBase
				sx={{ ml: 1, flex: 1 }}
				value={value?.text}
				placeholder="Search towers"
				inputProps={{ 'aria-label': 'search towers' }}
				onChange={(e:any) => onChange(e.target.value)}
			/>
			<Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
			<IconButton sx={{ p: '10px' }} aria-label="directions" onClick={onClear}>
				<CloseIcon />
			</IconButton>
		</Paper>
	)
}





