// import { useState } from 'react'
import { IonContent, IonPage } from '@ionic/react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import IosShareIcon from '@mui/icons-material/IosShare'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Header } from './Header'
import { getBrowserDetails } from '../utils/browser'

const Message = styled.div`
	& .instructions {
		padding: 15px;
		line-height: 32px;
	}
`

export default function InstallAppMessage(props:any) {
	const { onCancel } = props
	const { ios, android } = getBrowserDetails()

	return (<IonPage>
		<Header 
			left={<Button variant="text" sx={{ paddingLeft:'15px' }} onClick={() => onCancel()}>Close</Button>}
			middle={<Typography variant="h6" sx={{ padding:'0px 20px 0px 20px', width:'calc(100% - 67px)', display:'flex', justifyContent:'center' }}>Installation Steps</Typography>}/>
		<IonContent className="ion-padding">
			{ios && <Message>
				<Typography variant="body1" className="instructions">
					To add this app to your iOS device's homescreen, please follow these steps:
				</Typography>
				<Typography variant="body1" className="instructions">
					1. Open this page in Safari.<br/>
					2. In Safari's bottom menu, click on the <IosShareIcon style={{ marginBottom: '-3px' }}/> icon.<br/>
					3. Scroll until you find the "Add to Home Screen" option, then select it.
				</Typography>
			</Message>}
			{!ios && android && <Message>
				<Typography variant="body1" className="instructions">
					To add this app to your Android device's homescreen, please follow these steps:
				</Typography>
				<Typography variant="body1" className="instructions">
					1. Open this page in Chrome.<br/>
					2. Click on the <MoreVertIcon style={{ marginBottom: '-4px' }}/> icon to open the menu.<br/>
					3. Scroll until you find the "Install app" or "Add to Home screen" option, then select it.
				</Typography>
			</Message>}
			{!ios && !android && <Message>
				<Typography variant="body1" className="instructions">
					To add this app to your device's homescreen, please follow these steps:
				</Typography>
				<Typography variant="body1" className="instructions">
					1. Open this page in Chrome.<br/>
					2. Click on the <MoreVertIcon style={{ marginBottom: '-4px' }}/> icon to open the menu.<br/>
					3. Scroll until you find the "Install app" or "Add to Home screen" option, then select it.
				</Typography>
			</Message>}
		</IonContent>
	</IonPage>)
}





